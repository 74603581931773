class plane {
	aircraft;
	moments = {};
	limits = {};
	empty_cg = {};
	_loading = {};
	_rampCG = {};
	_takeoffCG = {};
	_landingCG = {};
	_emptyFuelCG = {};
	_status = {};
	_overweight = false;
	_underweight = false;
	_forwardCGexceeded = false;
	_aftCGexceeded = false;
	_alerts = [];
	_notes = "";
	
	static get fuel_weight_per_gal() { return 6; }
	static get oil_weight_per_qt() { return 1.875; }
	
	constructor(aircraft, moments, limits, empty_cg) {
		this.aircraft = aircraft;
		this.moments = moments;
		this.limits = limits;
		this.empty_cg = empty_cg;
	}

	get loading() {
		return this._loading;
	}

	set loading(val) {
		this._loading = val;
		this.recalculate();
	}

	get notes() {
		return this._notes;
	}

	set notes(val) {
		this._notes = val;
	}

	changeLoading(loading)
	{
		this._loading = { ...this._loading,
						  ...loading };
		this.recalculate();
	}

	get alerts() {
		return this._alerts;
	}

	get status() {
		return this._status;
	}

	get underweight() {
		return this._underweight;
	}

	get overweight() {
		return this._overweight;
	}
	
	get forwardCGexceeded() {
		return this._forwardCGexceeded;
	}

	get aftCGexceeded() {
		return this._aftCGexceeded;
	}

	get inLimits() {
		if (this.overweight)
			return false;
		if (this.underweight)
			return false;
		if (this._forwardCGexceeded)
			return false;
		if (this._aftCGexceeded)
			return false;

		return true;
	}

	recalculate()
	{
		let moment = this.moment(this.empty_cg.weight, this.empty_cg.arm);
		let weight = this.empty_cg.weight;

		if (this.moments.oil != null) {
			moment += this.moment(this._loading.oil * plane.oil_weight_per_qt, this.moments.oil);
			weight += this._loading.oil * plane.oil_weight_per_qt;
		}

		moment += this.moment(this._loading.pilot, this.moments.front_seat);
		weight += this._loading.pilot;

		moment += this.moment(this._loading.passenger, this.moments.front_seat);
		weight += this._loading.passenger;

		if (this.moments.rear_seat != null) {
			moment += this.moment(this._loading.rear_one, this.moments.rear_seat);
			weight += this._loading.rear_one;

			moment += this.moment(this._loading.rear_two, this.moments.rear_seat);
			weight += this._loading.rear_two;
		}

		moment += this.moment(this._loading.baggage, this.moments.baggage);
		weight += this._loading.baggage;

		if (this.moments.baggage2 != null) {
			moment += this.moment(this._loading.baggage2, this.moments.baggage2);
			weight += this._loading.baggage2;
		}

		this._emptyFuelCG = { weight: weight, arm: this.arm(weight, moment), level: "G" };

		moment += this.moment(this._loading.fuel * plane.fuel_weight_per_gal, this.moments.fuel);
		weight += this._loading.fuel * plane.fuel_weight_per_gal;

		this._rampCG = { weight: weight, arm: this.arm(weight, moment), level: "G" };

		moment -= this.moment(this._loading.taxi_runup_fuel_burn * plane.fuel_weight_per_gal, this.moments.fuel);
		weight -= this._loading.taxi_runup_fuel_burn * plane.fuel_weight_per_gal;

		this._takeoffCG = { weight: weight, arm: this.arm(weight, moment), level: "G" };

		moment -= this.moment(this._loading.cruise_fuel_burn * plane.fuel_weight_per_gal, this.moments.fuel);
		weight -= this._loading.cruise_fuel_burn * plane.fuel_weight_per_gal;

		this._landingCG = { weight: weight, arm: this.arm(weight, moment), level: "G" };

		// Status
		this._alerts = [];
		this._status =  { level: "G", message: "In Limits" };
		this._overweight = false;
		this._underweight = false;
		this._forwardCGexceeded = false;
		this._aftCGexceeded = false;

		if (this._rampCG.weight > this.limits.ramp)
		{
			this._overweight = true;
			this._status = { level: "E", message: "Overweight" };
			let amount = (this._rampCG.weight - this.limits.ramp);
			this._alerts.push( { level: "E", message: "Ramp Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
		}

		if (this._takeoffCG.weight > this.limits.take_off)
		{
			this._overweight = true;
			this._status = { level: "E", message: "Overweight" };
			let amount = (this._takeoffCG.weight - this.limits.take_off);
			this._alerts.push( { level: "E", message: "Takeoff Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
			this._takeoffCG.level = "E";
		}

		if (this.limits.front_seat != null)
		{
			if (this._loading.pilot + this._loading.passenger > this.limits.front_seat)
			{
				this._overweight = true;
				this._status = { level: "E", message: "Overweight" };
				let amount = (this._loading.pilot + this._loading.passenger - this.limits.front_seat);
				this._alerts.push( { level: "E", message: "Front Seat Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
				}
		}
		
		if (this.limits.rear_seat != null)
		{
			if (this._loading.rear_one + this._loading.rear_two > this.limits.rear_seat)
			{
				this._overweight = true;
				this._status = { level: "E", message: "Overweight" };
				let amount = (this._loading.rear_one + this._loading.rear_two - this.limits.rear_seat);
				this._alerts.push( { level: "E", message: "Rear Seat Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
				}
		}
		
		if (this.limits.baggage != null)
		{
			if (this._loading.baggage > this.limits.baggage)
			{
				this._overweight = true;
				this._status = { level: "E", message: "Overweight" };
				let amount = (this._loading.baggage - this.limits.baggage);
				this._alerts.push( { level: "E", message: "Baggage Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
				}
		}
		
		if (this.limits.baggage2 != null)
		{
			if (this._loading.baggage2 > this.limits.baggage2)
			{
				this._overweight = true;
				this._status = { level: "E", message: "Overweight" };
				let amount = (this._loading.baggage2 - this.limits.baggage2);
				this._alerts.push( { level: "E", message: "Baggage Two Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
				}
		}
		
		if (this.limits.baggage_combined != null)
		{
			if (this._loading.baggage + this._loading.baggage2 > this.limits.baggage_combined)
			{
				this._overweight = true;
				this._status = { level: "E", message: "Overweight" };
				let amount = (this._loading.baggage + this._loading.baggage2 - this.limits.baggage_combined);
				this._alerts.push( { level: "E", message: "Combined Baggage Weight Over Limit by " +  amount.toFixed(0) + " lbs" } );
				}
		}
		
		if (this._landingCG.weight < this.limits.minimum)
		{
			this._underweight = true;
			this._status =  { level: "E", message: "Underweight" };
			let amount = (this.limits.minimum - this._landingCG.weight);
			this._alerts.push( { level: "E", message: "Landing Weight Under Limit by " +  amount.toFixed(0) + " lbs" } );
			this._landingCG.level = "E";
		}
		if (this._emptyFuelCG.weight < this.limits.minimum)
		{
			this._underweight = true;
			this._status =  { level: "E", message: "Underweight" };
			let amount = (this.limits.minimum - this._emptyFuelCG.weight);
			this._alerts.push( { level: "E", message: "Empty Fuel Weight Under Limit by " +  amount.toFixed(0) + " lbs" } );
			this._emptyFuelCG.level = "E";
		}

		// All limit calculations are to 0.1 "
		let forward_limit = this.forwardLimit(this._takeoffCG.weight).toFixed(1);
		if (this._takeoffCG.arm.toFixed(1) < forward_limit)
		{
			this._forwardCGexceeded = true;
			if (this._status.level === "G")
				this._status = { level: "E", message: "Forward of CG Limit" };
			let amount = forward_limit - this._takeoffCG.arm.toFixed(1);
			this._alerts.push( { level: "E", message: "Takeoff CG exceeds Forward Limit by " +  amount.toFixed(1) + " inches" } );
			this._takeoffCG.level = "E";
		}
		forward_limit = this.forwardLimit(this._landingCG.weight).toFixed(1);
		if (this._landingCG.arm.toFixed(1) < forward_limit)
		{
			this._forwardCGexceeded = true;
			if (this._status.level === "G")
				this._status = { level: "E", message: "Forward of CG Limit" };
			let amount = forward_limit - this._landingCG.arm.toFixed(1);
			this._alerts.push( { level: "E", message: "Landing CG exceeds Forward Limit by " +  amount.toFixed(1) + " inches" } );
			this._landingCG.level = "E";
		}
		forward_limit = this.forwardLimit(this._emptyFuelCG.weight).toFixed(1);
		if (this._emptyFuelCG.arm.toFixed(1) < forward_limit)
		{
			this._forwardCGexceeded = true;
			if (this._status.level === "G")
				this._status = { level: "E", message: "Forward of CG Limit" };
			let amount = forward_limit - this._emptyFuelCG.arm.toFixed(1);
			this._alerts.push( { level: "E", message: "Empty Fuel CG exceeds Forward Limit by " +  amount.toFixed(1) + " inches" } );
			this._emptyFuelCG.level = "E";
		}

		// All limit calculations are to 0.1 "
		let aft_limit = this.aftLimit(this._takeoffCG.weight).toFixed(1);
		if (this._takeoffCG.arm.toFixed(1) > aft_limit)
		{
			this._aftCGexceeded = true;
			if (this._status.level === "G")
				this._status = { level: "E", message: "Aft of CG Limit" };
			let amount = this._takeoffCG.arm.toFixed(1) - aft_limit;
			this._alerts.push( { level: "E", message: "Takeoff CG exceeds Aft Limit by " +  amount.toFixed(1) + " inches" } );
			this._takeoffCG.level = "E";
		}
		aft_limit = this.aftLimit(this._landingCG.weight).toFixed(1);
		if (this._landingCG.arm.toFixed(1) > aft_limit)
		{
			this._aftCGexceeded = true;
			if (this._status.level === "G")
				this._status = { level: "E", message: "Aft of CG Limit" };
			let amount = this._landingCG.arm.toFixed(1) - aft_limit;
			this._alerts.push( { level: "E", message: "Landing CG exceeds Aft Limit by " +  amount.toFixed(1) + " inches" } );
			this._landingCG.level = "E";
		}
		aft_limit = this.aftLimit(this._emptyFuelCG.weight).toFixed(1);
		if (this._emptyFuelCG.arm.toFixed(1) > aft_limit)
		{
			this._aftCGexceeded = true;
			if (this._status.level === "G")
				this._status = { level: "W", message: "Aft of CG Limit" };
			let amount = this._emptyFuelCG.arm.toFixed(1) - aft_limit;
			this._alerts.push( { level: "W", message: "Empty Fuel CG exceeds Aft Limit by " +  amount.toFixed(1) + " inches" } );
			this._emptyFuelCG.level = "E";
		}
		
	}

	get rampCG() {
		return this._rampCG;
	}

	get takeOffCG() {
		return this._takeoffCG;
	}

	get landingCG() {
		return this._landingCG;
	}

	get emptyFuelCG() {
		return this._emptyFuelCG;
	}

	forwardLimit(weight) {
		return this.getLimit(this.limits.forward_cg, weight);
	}

	aftLimit(weight) {
		return this.getLimit(this.limits.aft_cg, weight);
	}

	getLimit(cg_arr, weight)
	{
		const limits = cg_arr.sort((a, b) => a.weight > b.weight ? 1 : -1);
 
		if (limits[0].weight > weight)
			return limits[0].arm;

		let prev = limits[0];
		let below = prev;
		for (var limit of limits) {
			if (limit.weight >= weight)
			{
				if (limit.arm === below.arm)
					return below.arm;
				// Calculate based on the slope.
				let slope = (limit.arm - below.arm) / (limit.weight - below.weight);
				let delta = (weight - below.weight) * slope;
				return below.arm + delta;
			}
			prev = below;
			below = limit;
		}

		// We are overweight - use the extended line of the last weight range to extrapolate an envelope
		// At this stage it should be prev --> below --> data-point
		let slope = (below.arm - prev.arm) / (below.weight - prev.weight);
		let delta = (weight - prev.weight) * slope;
		return prev.arm + delta;
	}

	moment(weight, arm) {
		return weight * arm;
	}

	arm(weight, moment) {
		return moment / weight;
	}
}

export default plane;
