import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import logo from './img/OnPurposeLogo.png';
import ClearancePractice from './IFR/ClearancePractice'
import WeightAndBalance from './WFRF/WeightAndBalance'
import PantherWeightAndBalance from './Panther/WeightAndBalance'
import Atis from './WFRF/atis'

import NavBar from './WFRF/components/NavBar';

export default function App() {
  return (
    <Router>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <WeightAndBalance /> {/* <Home /> */}
          </Route>
          <Route path="/ClearancePractice">
            <ClearancePractice />
          </Route>
          <Route path="/PantherAero">
            <PantherWeightAndBalance />
          </Route>
          <Route path="/WeightAndBalance">
            <WeightAndBalance />
          </Route>
          <Route path="/atis">
            <Atis />
          </Route>
          <Route path="/home">
            <NavBar />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
              <h1>Home</h1>
            </div>
          </Route>
          <Route path="/about">
            <NavBar />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
              <h1>About</h1>
            </div>
          </Route>
        </Switch>
    </Router>
  );
}


function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-panel">
          <p className="App-about">
            On Purpose Flight is dedicated to enhancing flight safety through learning and understanding.  And by flying <b>On <i>Purpose</i></b>.
          </p>
          <p className="App-about">
            On Purpose?  Yes, not by accident, but with a clear and definable reason for everything we do.  Every control input, every risk decision, everything!
          </p>
        </div>
      </header>
    </div>
  );
}
