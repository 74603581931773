import plane from '../WFRF/plane';

function PersistLoading(plane) {
	localStorage.setItem(plane.aircraft, JSON.stringify(plane.loading));
  }
  
  function InitializeLoading(plane) {
	let local = localStorage.getItem(plane.aircraft);
	if (local != null)
	{
	  plane.changeLoading(JSON.parse(local));
	}
  }
  
  class pantherPlanes {

	constructor(props) {
  
	  let n757lg = new plane('N757LG',
	  { fuel: 42.17,
		front_seat: 39,
		baggage: 64,
		baggage2: 84 },
	  { effective_date: "03-19-1991",
		ramp: 1675,
		take_off: 1670,
		landing: 1670,
		baggage: 120,
		baggage2: 40,
		baggage_combined: 120,
		forward_cg: [
		  { weight: 1670, arm: 32.6 },
		  { weight: 1350, arm: 31.0 },
		  { weight: 1000, arm: 31.0 }
		],
		aft_cg: [
		  { weight: 1670, arm: 36.5 },
		  { weight: 1000, arm: 36.5 }
		]
	  },
	  { weight: 1165.2, arm: 29.76 });
   
	  n757lg.loading = { 
		 fuel: 24.5,
		 pilot: 170,
		 passenger: 170,
		 baggage: 23,
		 taxi_runup_fuel_burn: 0.9,
		 cruise_fuel_burn: 20
	  };
	  InitializeLoading(n757lg);
  
	  let n739ap = new plane('N739AP',
	  { fuel: 48,
		oil: null,
		front_seat: 37,
		rear_seat: 73,
		baggage: 95,
		baggage2: 123 },
	  { effective_date: "02-08-1990",
		ramp: 2307,
		take_off: 2300,
		landing: 2300,
		baggage: 120,
		baggage2: 50,
		baggage_combined: 120,
		forward_cg: [
		  { weight: 2300, arm: 41.0 },
		  { weight: 1950, arm: 35.0 },
		  { weight: 1500, arm: 35.0 }
		],
		aft_cg: [
		  { weight: 2300, arm: 47.3 },
		  { weight: 1500, arm: 47.3 }
		]
	  },
	  { weight: 1465.5, arm: 38.93 });
   
	  n739ap.loading = { 
		 fuel: 40,
		 oil: 6,
		 pilot: 205,
		 passenger: 180,
		 rear_one: 200,
		 rear_two: 120,
		 baggage: 70,
		 baggage2: 50,
		 taxi_runup_fuel_burn: 1.1,
		 cruise_fuel_burn: 20
	  };
	  InitializeLoading(n739ap);
  
	  let plane_list = [ n757lg, n739ap ];
  
	  this.plane_list = plane_list;
	}
	
  }
  
  export default pantherPlanes;
  