import wfrfPlanes from './definePlanes';
import WeightAndBalancePicker from './WeightAndBalancePicker';
import React from 'react';

class WeightAndBalance extends React.Component {

  constructor(props) {
    super(props);

    let planes = new wfrfPlanes();

    this.state = {
      planes: planes.plane_list,
    };
  }

  render() {
      return (
      <WeightAndBalancePicker planes={ this.state.planes } />
    );
  }
}

export default WeightAndBalance;
