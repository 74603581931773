import React, { Component } from "react";

export default class PopUp extends Component {
  ignoreClick = (e) => {
    e.stopPropagation();
  };
  handleClick = () => {
    this.props.toggle();
  };

render() {
  console.log("formType: " + this.props.formType);
  return (
   <div className="modal" onClick={this.handleClick}>
     <div className="modal-content" onClick={this.ignoreClick}>
     <span className="close" onClick={this.handleClick}>&times;    </span>
     { this.props.formType }
    </div>
   </div>
  );
 }
}