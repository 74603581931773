import React from 'react';

import { Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink } from './NavBarElements';

class NavBar extends React.Component {

	constructor(props) {
		super(props);
	
		this.state = {
		  popup: false,
		  wbLink: props.wbLink != null ? props.wbLink : "/WeightAndBalance",
		};
	}	

	handleBarsClick = () => {
		console.log("Click: " + this.state.popup);
		this.setState({popup: !this.state.popup});
	}

	render() {

		return (
		<>
			<Nav>
				<NavLink to={this.state.wbLink}>
					<h1>Gx&trade;</h1>
				</NavLink>
				<Bars onClick={this.handleBarsClick}/>
				<NavMenu className={this.state.popup ? 'menu-show' : 'menu-hide'}>
					<NavLink to={this.state.wbLink}>
						W&amp;B
					</NavLink>
{/*
					<NavLink to="/atis">
						ATIS
					</NavLink>
					<NavLink to="/take-off">
						Take Off
					</NavLink>
					<NavLink to="/cruise">
						Cruise
					</NavLink>
*/}
				</NavMenu>
{/*
				<NavBtn>
					<NavBtnLink to="sign-in">Sign In</NavBtnLink>
				</NavBtn>
*/}
			</Nav>
		</>
		);
	}
};

export default NavBar;