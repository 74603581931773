class AtisData {
	_data = {
		airport: 'KUES',
		code: 'Z',
		zulu_time: '0000',
		wind_direction: '0',
		wind_speed: '0',
		wind_gust: '0',
		visibility: '0',
		ceiling_1: '10000',
		coverage_1: 'CLR',
		ceiling_2: '10000',
		coverage_2: 'CLR',
		ceiling_3: '0',
		coverage_3: 'CLR',
		temp: '15',
		dewpoint: '0',
		altimeter: '2992',
		runway_in_use: '',
		remarks: '',
	}

	get airport() {return this._data.airport;}
	set airport(val) {this._data.airport = val.toUpperCase();}

	get code() {return this._data.code;}
	set code(val) {this._data.code = val.toUpperCase();}

	get zulu_time() {return this._data.zulu_time;}
	set zulu_time(val) {this._data.zulu_time = val;}

	get wind_direction() {return this._data.wind_direction;}
	set wind_direction(val) {this._data.wind_direction = val;}

	get wind_speed() {return this._data.wind_speed;}
	set wind_speed(val) {this._data.wind_speed = val;}

	get wind_gust() {return this._data.wind_gust;}
	set wind_gust(val) {this._data.wind_gust = val;}

	get visibility() {return this._data.visibility;}
	set visibility(val) {this._data.visibility = val;}

	get ceiling_1() {return this._data.ceiling_1;}
	set ceiling_1(val) {this._data.ceiling_1 = val;}

	get coverage_1_abbrev() {return this.Abbrev(this._data.coverage_1);}
	get coverage_1() {return this._data.coverage_1;}
	set coverage_1(val) {this._data.coverage_1 = val.toUpperCase();}

	get ceiling_2() {return this._data.ceiling_2;}
	set ceiling_2(val) {this._data.ceiling_2 = val;}

	get coverage_2_abbrev() {return this.Abbrev(this._data.coverage_2);}
	get coverage_2() {return this._data.coverage_2;}
	set coverage_2(val) {this._data.coverage_2 = val.toUpperCase();}

	get ceiling_3() {return this._data.ceiling_3;}
	set ceiling_3(val) {this._data.ceiling_3 = val;}

	get coverage_3_abbrev() {return this.Abbrev(this._data.coverage_3);}
	get coverage_3() {return this._data.coverage_3;}
	set coverage_3(val) {this._data.coverage_3 = val.toUpperCase();}

	get temp() {return this._data.temp;}
	set temp(val) {this._data.temp = val;}

	get dewpoint() {return this._data.dewpoint;}
	set dewpoint(val) {this._data.dewpoint = val;}

	get altimeter() {return this._data.altimeter;}
	set altimeter(val) {this._data.altimeter = val;}

	get runway_in_use() {return this._data.runway_in_use;}
	set runway_in_use(val) {this._data.runway_in_use = val;}

	get remarks() {return this._data.remarks;}
	set remarks(val) {this._data.remarks = val;}

	constructor() {
	}

	Abbrev(cov) {
		if (cov === "C") return "CLR";
		if (cov === "F") return "FEW";
		if (cov === "S") return "SKT";
		if (cov === "B") return "BKN";
		if (cov === "O") return "OVC";
		return "UNK";
	}

	static loadData() {
		let local = localStorage.getItem('ATIS');
		if (local != null)
		{
			let stored = JSON.parse(local);
			let data = new AtisData();
			data._data = {...data._data, ...stored}
			return data;
		}
		return new AtisData();
	}

	storeData() {
		localStorage.setItem('ATIS', JSON.stringify(this._data));
	}

}

export default AtisData;