import './WFRF.css';
import './WnB.css';

import plane from './plane';

import React from 'react';
import { Formik, Field, Form } from 'formik';

import { VictoryChart, VictoryTheme, VictoryLine, VictoryAxis, VictoryScatter, VictoryLabel } from "victory";
import NavBar from './components/NavBar/index.js';
import wfrfPlanes from './definePlanes';
import SpecificAircraftPage from './SpecificAircraftPage'

function CGtoXY(cg) {
  //console.log("CGtoXY: " + JSON.stringify(cg));
  return { x: cg.arm, y: cg.weight };
};

class EnvelopeGraph extends React.Component {

  constructor(props) {
    super(props);
    console.log(`constructor plane:`, this.props.current_plane.aircraft);
  }

  ForwardCG(plane) {
    const data = plane.limits.forward_cg.sort((a, b) => a.weight > b.weight ? 1 : -1).map((datum) =>
      { return {x: datum.arm, y: datum.weight}; }
    );

    return (
      <VictoryLine
        style={{
          data: { stroke: "black" },
          parent: { border: "1px solid #ccc"}
        }}
        data={data}
      />
    );
  }

  MaxWeightCG(plane) {
    const fore = plane.limits.forward_cg.reduce(function (p, v) {
      return ( p.weight > v.weight ? p : v );
    });
    const aft = plane.limits.aft_cg.reduce(function (p, v) {
      return ( p.weight > v.weight ? p : v );
    });
    var data = [ fore, aft ];    
    data = data.sort((a, b) => a.weight > b.weight ? 1 : -1).map((datum) =>
      { return {x: datum.arm, y: datum.weight}; }
    );

    console.log('MaxWeightCG:', data);

    return (
      <VictoryLine
        style={{
          data: { stroke: "black" },
          parent: { border: "1px solid #ccc"}
        }}
        data={data}
      />
    );
  }

  AftCG(plane) {
    const data = plane.limits.aft_cg.sort((a, b) => a.weight > b.weight ? 1 : -1).map((datum) =>
      { return {x: datum.arm, y: datum.weight}; }
    );

    return (
      <VictoryLine
        style={{
          data: { stroke: "black" },
          parent: { border: "1px solid #ccc"}
        }}
        data={data}
      />
    );
  }

  MinWeightCG(plane) {
    const fore = plane.limits.forward_cg.reduce(function (p, v) {
      return ( p.weight < v.weight ? p : v );
    });
    const aft = plane.limits.aft_cg.reduce(function (p, v) {
      return ( p.weight < v.weight ? p : v );
    });
    var data = [ fore, aft ];    
    data = data.sort((a, b) => a.weight > b.weight ? 1 : -1).map((datum) =>
      { return {x: datum.arm, y: datum.weight}; }
    );

    console.log('MinWeightCG:', data);

    return (
      <VictoryLine
        style={{
          data: { stroke: "black" },
          parent: { border: "1px solid #ccc"}
        }}
        data={data}
      />
    );
  }

  calculateDomain(plane) {
    var minX = Math.min(...plane.limits.forward_cg.map((datum) => { return datum.arm; }));
    var minY = Math.min(...plane.limits.forward_cg.map((datum) => { return datum.weight; }));
    var maxX = Math.max(...plane.limits.aft_cg.map((datum) => { return datum.arm; }));
    var maxY = Math.max(...plane.limits.aft_cg.map((datum) => { return datum.weight; }));

    var deltaX = (maxX - minX) * 0.1;
    minX = minX - deltaX;
    maxX = maxX + deltaX;

    var deltaY = (maxY - minY) * 0.1;
    minY = minY - deltaY;
    maxY = maxY + deltaY;

    return { min: { x: minX, y: minY }, max: { x: maxX, y: maxY } };
  }

  render() {
    const plane = this.props.current_plane;
    const domain = this.calculateDomain(plane);

    return (
      <VictoryChart
        className="wb-chart"
        theme={VictoryTheme.material} maxDomain={ domain.max } minDomain={ domain.min }>

        <VictoryLabel text={plane.aircraft + " - " + plane.limits.effective_date } x={180} y={30} textAnchor="middle"/>
        
        <VictoryAxis label="CG" style={{ axisLabel: {fontSize: 12, padding: -16} }}/>
        <VictoryAxis dependentAxis label="Weight" style={{ axisLabel: {fontSize: 12, padding: -16} }}/>

        {this.ForwardCG(plane)}
        {this.MaxWeightCG(plane)}
        {this.AftCG(plane)}
        {this.MinWeightCG(plane)}
        
        <VictoryLine
          style={{
            data: { stroke: "black", strokeWidth: "2"},
            parent: { border: "1px solid #ccc"}
          }}
          data={[
            CGtoXY(plane.takeOffCG),
            CGtoXY(plane.landingCG),
            CGtoXY(plane.emptyFuelCG),
        ]}
        />
        <VictoryScatter
            style={{ data: { fill: ({ datum }) => datum.color, fillOpacity: 0.5,
                             stroke: ({ datum }) => datum.color, strokeWidth: "2" } }}
            size={6}
            data={[
              { ...CGtoXY(plane.takeOffCG), ...{ symbol: "circle", color: plane.takeOffCG.level === "E" ? "red" : "green" }},
              { ...CGtoXY(plane.landingCG), ...{ symbol: "square", color: plane.landingCG.level === "E" ? "red" : "green" }},
              { ...CGtoXY(plane.emptyFuelCG), ...{ symbol: "diamond", color: "dark-gray" } }
            ]}
          />
      </VictoryChart>);
  }
}

const SELECT_VALUE_KEY = "MySelectValue";

class WeightAndBalancePicker extends React.Component {

  constructor(props) {
    super(props);
    const current_aircraft = JSON.parse(localStorage.getItem(SELECT_VALUE_KEY) ?? null);
    let current_plane = current_aircraft != null ? this.findPlane(props.planes, current_aircraft) : props.planes[0];
    if (current_plane == null)
      current_plane = props.planes[0];

    this.state = {
      planes: props.planes,
      current_plane: current_plane,
      selected: current_aircraft,
      wbLink: props.wbLink,
    };

    this.SelectPlane = this.SelectPlane.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.state.current_plane.selected = true;
  }

  findPlane(array, aircraft) {
    return array.find((plane) => {
      return plane.aircraft === aircraft;
    })
  }

  SelectPlane(event) {
    var aircraft = event.target.value;
    var plane = this.findPlane(this.state.planes, aircraft);

    var current_plane = this.state.current_plane;
    current_plane.selected = false;
    this.setState({ current_plane: plane, selected: aircraft });
    plane.selected = true;
}

  handleFocusChange = (e) => {
    const target = e.target;
    console.log ("handleFocusChange() " + target.type);
    if (target.type === 'number') {
      target.select();
    }
    //e.target.setSelectionRange(0, 999);
  }

  handleInputChange = (e, setFieldValue) => {
    const target = e.target;
    let value = target.type === 'checkbox' ? target.checked : parseFloat(target.value);
    const name = target.name;
    const plane = this.state.current_plane;

    if (isNaN(value))
      value = 0;

    console.log("handleInputChange: " + [name] + " = " + value);

    changeLoading(plane, { [name]: value });

    setFieldValue([name], value);
    this.setState({ });
  }


  render() {

    const current_plane = this.state.current_plane;
    const listItems = this.state.planes.map((plane) =>
      <option value={plane.aircraft}>{plane.aircraft}</option>
    );

    console.log("render(): " + JSON.stringify(current_plane));

    console.log("  Takeoff: " + JSON.stringify(current_plane.takeOffCG));
    console.log("  Landing: " + JSON.stringify(current_plane.landingCG));
    console.log("  Empty:   " + JSON.stringify(current_plane.emptyFuelCG));

    return (
      <SpecificAircraftPage planes={ this.state.planes } onSelectPlane={ this.SelectPlane } wbLink={ this.state.wbLink }>
        <div className="app-body">
          <div className="loading">

            <Formik
              enableReinitialize
              initialValues={current_plane.loading}>

              {props => (
                <Form className="input-form">
                  <label className="form-label gap-before">Pilot: </label>
                  <Field className="form-value gap-before" type="number" name="pilot" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                  <label className="form-units gap-before"> lbs</label>
                  
                  <label className="form-label">Co-Pilot:</label>
                  <Field className="form-value" type="number" name="passenger" onFocus={(e) => this.handleFocusChange(e)} onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                  <label className="form-units">lbs</label>

                  { current_plane.moments.rear_seat != null ?
                      <React.Fragment>
                        <label className="form-label gap-before">Rear 1: </label>
                        <Field className="form-value gap-before" type="number" name="rear_one" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                        <label className="form-units gap-before"> lbs</label>
                        
                        <label className="form-label">Rear 2:</label>
                        <Field className="form-value" type="number" name="rear_two" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                        <label className="form-units">lbs</label>
                      </React.Fragment> :
                    null
                  }

                  <label className="form-label gap-before">Baggage: </label>
                  <Field className="form-value gap-before" type="number" name="baggage" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                  <label className="form-units gap-before">lbs</label>
                  
                  { current_plane.limits.baggage2 != null ?
                      <React.Fragment>
                      <label className="form-label">Baggage 2: </label>
                      <Field className="form-value" type="number" name="baggage2" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                      <label className="form-units">lbs</label>
                      </React.Fragment> :
                    null
                  }

                  <label className="form-label gap-before">Fuel: </label>
                  <Field className="form-value gap-before" type="number" name="fuel" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                  <label className="form-units gap-before">gals</label>

                  { current_plane.moments.oil != null ?
                      <React.Fragment>
                      <label className="form-label">Oil: </label>
                      <Field className="form-value" type="number" name="oil" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                      <label className="form-units">qts</label>
                      </React.Fragment> :
                    null
                  }

                  <label className="form-header gap-before">Fuel Burn</label>

                  <label className="form-label">Taxi/Runup: </label>
                  <Field className="form-value" type="number" name="taxi_runup_fuel_burn" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                  <label className="form-units">gals</label>

                  <label className="form-label">Cruise: </label>
                  <Field className="form-value" type="number" name="cruise_fuel_burn" onChange={(e) => this.handleInputChange(e, props.setFieldValue)} onFocus={(e) => this.handleFocusChange(e)}/>
                  <label className="form-units">gals</label>
                </Form>
              )}
            </Formik>
            <div className="form-notes">
                    <br/>
                    <span>{current_plane.notes}</span>
                  </div>
          </div>
          <div className="loading-footer">
            { current_plane.alerts.map((alert) =>
              <div className={alert.level === "E" ? "alert-error": alert.level === "W" ? "alert-warning" : "alert-ok"}>{alert.message}</div>
            )}
          </div>

          <div className="graph-header">
            <div className={current_plane.status.level === "E" ? "alert-error": current_plane.status.level === "W" ? "alert-warning" : "alert-ok"} >
              {current_plane.status.message}
            </div>
          </div>

          <div className="graph">
            <EnvelopeGraph current_plane={this.state.current_plane}/>
          </div>

        </div>
      </SpecificAircraftPage>
    );
  }
}

function changeLoading(plane, loading) {
  plane.changeLoading(loading);

  PersistLoading(plane);
}

function PersistLoading(plane) {
  localStorage.setItem(plane.aircraft, JSON.stringify(plane.loading));
}

function InitializeLoading(plane) {
  let local = localStorage.getItem(plane.aircraft);
  if (local != null)
  {
    plane.changeLoading(JSON.parse(local));
  }
}

export default WeightAndBalancePicker;
