import pantherPlanes from './definePlanes';
import WeightAndBalancePicker from '../WFRF/WeightAndBalancePicker';
import React from 'react';

class PantherWeightAndBalance extends React.Component {

  constructor(props) {
    super(props);

    let planes = new pantherPlanes();

    this.state = {
      planes: planes.plane_list,
    };
  }

  render() {
      return (
      <WeightAndBalancePicker planes={ this.state.planes } wbLink="/PantherAero"/>
    );
  }
}

export default PantherWeightAndBalance;
