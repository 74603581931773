import './WFRF.css';
import './atis.css';

import wfrfPlanes from './definePlanes';
import AtisData from './atisData';

import React from 'react';
import SpecificAircraftPage from './SpecificAircraftPage'
import { Formik, Field, Form } from 'formik';
import { FaEdit } from 'react-icons/fa';

import PopUp from './PopUp';

  class Atis extends React.Component {

	constructor(props) {
	  super(props);

	  let planes = new wfrfPlanes();
	  let data = AtisData.loadData();

	  console.log("data: " + JSON.stringify(data));

	  this.handleFocusChange = this.handleFocusChange.bind(this);
	  this.handleInputChange = this.handleInputChange.bind(this);

	  this.state = {
		planes: planes.plane_list,
		seen: false,
		atis: data,
	  };
	}

	togglePop = () => {
		this.setState({
			seen: !this.state.seen
		});
	};
	
	showInput = (typ) => {
		this.setState({
			formType: typ
		});
	};

	showNextInput = (typ) => {
		this.setState({
			formType: typ,
			seen: true
		});
	};

	getForm = (typ) => {
		let codeDisplayStyle = typ === "CODE" ? { } : { display:  'none'	};
		let windDisplayStyle = typ === "WIND" ? { } : { display:  'none'	};
		let visDisplayStyle = typ === "VIS" ? { } : { display:  'none'	};
		let skyDisplayStyle = typ === "SKY" || typ === "SKY2" || typ === "SKY3" ? { } : { display:  'none'	};
		let tempDisplayStyle = typ === "TEMP" ? { } : { display:  'none'	};
		let altDisplayStyle = typ === "ALT" ? { } : { display:  'none'	};
		let rwyDisplayStyle = typ === "RWY" ? { } : { display:  'none'	};
		let remDisplayStyle = typ === "REM" ? { } : { display:  'none'	};

		if (typ === "CODE")
			this.setFocus("airport");
		else if (typ === "WIND")
			this.setFocus("wind_direction");
		else if (typ === "VIS")
			this.setFocus("visibility");
		else if (typ === "SKY")
			this.setFocus("ceiling_1");
		else if (typ === "SKY2")
			this.setFocus("ceiling_2");
		else if (typ === "SKY3")
			this.setFocus("ceiling_3");
		else if (typ === "TEMP")
			this.setFocus("temp");
		else if (typ === "ALT")
			this.setFocus("altimeter");
		else if (typ === "RWY")
			this.setFocus("runway_in_use");
		else if (typ === "REM")
			this.setFocus("remarks");

		return (
			<Formik
				initialValues={{airport: this.state.atis.airport,
								code: this.state.atis.code,
								zulu_time: this.state.atis.zulu_time,
								wind_direction: this.state.atis.wind_direction,
								wind_speed: this.state.atis.wind_speed,
								wind_gust: this.state.atis.wind_gust,
								visibility: this.state.atis.visibility,
								ceiling_1: this.state.atis.ceiling_1,
								coverage_1: this.state.atis.coverage_1,
								ceiling_2: this.state.atis.ceiling_2,
								coverage_2: this.state.atis.coverage_2,
								ceiling_3: this.state.atis.ceiling_3,
								coverage_3: this.state.atis.coverage_3,
								temp: this.state.atis.temp,
								dewpoint: this.state.atis.dewpoint,
								runway_in_use: this.state.atis.runway_in_use,
								remarks: this.state.atis.remarks,
								}}
								enableReinitialize>
			{props => (

				<Form>

				<div className="input-form" style={codeDisplayStyle}>
					<button type="button" name="CodePrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("REM"); }}>
						Prev
					</button>

					<label className="form-label gap-before">Airport: </label>
					<Field className="form-value gap-before uppercase" type="text" name="airport"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 4, "code")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					
					<label className="form-label gap-before">Information: </label>
					<Field className="form-value gap-before uppercase" type="text" name="code"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 1, "zulu_time")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					
					<label className="form-label gap-before">Time: </label>
					<Field className="form-value gap-before" type="tel" name="zulu_time"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 4, "wind_direction", "WIND")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">Z</label>

					<button type="button" name="CodeNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("WIND"); }}>
						Next
					</button>
				</div>

				<div className='input-form' style={windDisplayStyle}>
					<button type="button" name="WindPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("CODE"); }}>
						Prev
					</button>

					<label className="form-label gap-before">Wind:</label>
					<Field className="form-value gap-before" type="tel" name="wind_direction"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 3, "wind_speed")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">&deg;</label>

					<label className="form-label gap-before">@</label>
					<Field className="form-value gap-before" type="tel" name="wind_speed"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 2, "wind_gust")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">Kts,</label>
					
					<label className="form-label gap-before">Gust:</label>
					<Field className="form-value gap-before" type="tel" name="wind_gust"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 2, "visibility", "VIS")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">Kts</label>
					
					<button type="button" name="WindNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("VIS"); }}>
						Next
					</button>
				</div>

				<div className='input-form' style={visDisplayStyle}>

					<button type="button" name="VisPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("WIND"); }}>
						Prev
					</button>

  					<label className="form-label gap-before">Visibility:</label>
					<Field className="form-value gap-before" type="tel" name="visibility"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 2, "ceiling_1", "SKY")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">SM</label>
					
					<button type="button" name="VisNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("SKY"); }}>
						Next
					</button>

				</div>

				<div className='input-form' style={skyDisplayStyle}>

					<button type="button" name="SkyPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("VIS"); }}>
						Prev
					</button>

					<label className="form-header gap-before">Sky:</label>

					<Field className="form-value gap-before" type="tel" name="ceiling_1"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 2, "coverage_1")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">'00 ft</label>

					<Field className="form-value-2 gap-before uppercase" type="text" name="coverage_1"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 1, "ceiling_2")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units-2 gap-before" >Clr Few Sct Bkn Ovc</label>

					<label className="form-label"></label>
					<Field className="form-value" type="tel" name="ceiling_2"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 2, "coverage_2")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units">'00 ft</label>

					<Field className="form-value-2 uppercase" type="text" name="coverage_2"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 1, "ceiling_3")}
						onFocus={(e) => this.handleFocusChange(e)}/>

					<label className="form-label"></label>
					<Field className="form-value" type="tel" name="ceiling_3"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 2, "coverage_3")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units">'00 ft</label>

					<Field className="form-value-2 uppercase" type="text" name="coverage_3"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 1, "temp", "TEMP")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					
					<button type="button" name="SkyNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("TEMP"); }}>
						Next
					</button>

				</div>

				<div className='input-form' style={tempDisplayStyle}>
				
					<button type="button" name="TempPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("SKY"); }}>
						Prev
					</button>

					<label className="form-label gap-before">Temp:</label>
					<Field className="form-value gap-before" type="tel" name="temp"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 3, "dewpoint")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">&deg;C</label>

					<label className="form-label gap-before">Dew:</label>
					<Field className="form-value gap-before" type="tel" name="dewpoint"
						onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 3, "altimeter", "ALT")}
						onFocus={(e) => this.handleFocusChange(e)}/>
					<label className="form-units gap-before">&deg;C</label>
					
					<button type="button" name="TempNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("ALT"); }}>
						Next
					</button>

				</div>

				<div className='input-form' style={altDisplayStyle}>

					<button type="button" name="AltPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("TEMP"); }}>
						Prev
					</button>

					<label className="form-label gap-before">Alt:</label>
					<Field className="form-value gap-before" type="tel" name="altimeter"
						   onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 4, "runway_in_use", "RWY")}
						   onFocus={(e) => this.handleFocusChange(e)}
						   value={this.state.atis.altimeter}/>
					<label className="form-units gap-before">inHg</label>
					
					<button type="button" name="AltNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("RWY"); }}>
						Next
					</button>

				</div>

				<div className='input-form' style={rwyDisplayStyle}>

					<button type="button" name="RwyPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("ALT"); }}>
						Prev
					</button>

					<label className="form-label gap-before">Runway:</label>
					<Field className="form-value gap-before" type="tel" name="runway_in_use"
						   onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 4, "remarks", "RMK")}
						   onFocus={(e) => this.handleFocusChange(e)}/>
					
					<button type="button" name="RwyNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("REM"); }}>
						Next
					</button>

				</div>

				<div className='input-form' style={remDisplayStyle}>

					<button type="button" name="RemPrev" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("RWY"); }}>
						Prev
					</button>

					<label className="form-label-2 gap-before">Remarks:</label>
					<div className="form-span-2-3">
						<Field className="form-value gap-before" type="textbox" name="remarks"
						   onChange={(e) => this.autoNextOnInputChange(e, props.setFieldValue, 99, "airport", "CODE")}
						   onFocus={(e) => this.handleFocusChange(e)}/>
					</div>
					
					<button type="button" name="RemNext" className="form-span-1 gap-before"
							onClick={ (e) => { this.showNextInput("CODE"); }}>
						Next
					</button>

				</div>

				</Form>
			)}
			</Formik>
		)
	}

	handleFocusChange = (e) => {
		const target = e.target;

		target.select();
	  }
	
	  handleInputChange = (e, setFieldValue) => {
		const target = e.target;
		let value = target.type === 'checkbox' ? target.checked : parseFloat(target.value);
		const name = target.name;
		const plane = this.state.current_plane;
	
		if (isNaN(value))
		  value = 0;
	
		console.log("handleInputChange: " + [name] + " = " + value + "(" + value.toString().length + ")");
	
		/*
		setFieldValue([name], value);
		this.setState({ });
		*/
	}
	
	setFocus = (nextField) => {
		setTimeout(() => {
				const nextf = document.querySelector(`[name=${nextField}]`);
				if (nextf != null) {
					nextf.focus();
				}
			},
			200);

	}

	autoNextOnInputChange = (e, setFieldValue, sizeLimit, nextField, nextTyp) => {
		const target = e.target;
		//let value = target.type === 'checkbox' ? target.checked : target.type === 'tel' ? parseFloat(target.value) : target.value;
		let value = target.value;
		const name = target.name;
		const plane = this.state.current_plane;
	
		console.log("autoNextOnInputChange: " + [name] + " = " + value);
	
		if (value.toString().length >= sizeLimit) {
			console.log("Goto Next Field");
			const nextf = document.querySelector(`[name=${nextField}]`);
			if (nextf != null) {
				console.log("Go To: " + nextField + " - " + nextf.tagName);
				if (nextf.tagName == "BUTTON")
					nextf.click();
				else
				{
					if (nextTyp != null)
					{
						setTimeout(() => {
								nextf.focus();
							},
							200);
						this.showNextInput(nextTyp);
					}
					else {
						nextf.focus();
					}
			
			
				}
			}
		}
		if (setFieldValue != null)
			setFieldValue([name], value);
		
		this.state.atis[name] = value;
		this.state.atis.storeData();
	}
	
	render() {
		return (
		<SpecificAircraftPage planes={ this.state.planes }>
			<div className="app-body">
				<div className="loading" onClick={this.togglePop}>
					<div className="atis-values">
						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("CODE") }/>
						<span className="col1 edit" onClick={ (e) => this.showInput("CODE") }>Airport: <b>{this.state.atis.airport}</b></span>
						<span className="col2 edit" onClick={ (e) => this.showInput("CODE") }>Information <b>{this.state.atis.code}</b></span>
						<span className="col3 edit" onClick={ (e) => this.showInput("CODE") }><b>{this.state.atis.zulu_time}Z</b></span>
						
						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("WIND") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("WIND") }>Wind:</span>
						{this.state.atis.wind_speed > 0 ?
								<span className="col2 edit" onClick={ (e) => this.showInput("WIND") }><b>{this.state.atis.wind_direction} @ {this.state.atis.wind_speed}</b></span>
							:
							<span className="col2 edit" onClick={ (e) => this.showInput("WIND") }><b>Calm</b></span>
						}
						{this.state.atis.wind_gust > 0 ?
								<span className="col3 edit" onClick={ (e) => this.showInput("WIND") }>Gust <b>{this.state.atis.wind_gust}</b></span>
							:
								null
						}

						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("VIS") }/>
						<input type="hidden" name="VIS" onClick={ (e) => this.showInput("VIS") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("VIS") }>Visibility:</span>
						<span className="col2 edit" onClick={ (e) => this.showInput("VIS") }><b>{this.state.atis.visibility}</b> SM</span>

						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("SKY") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("SKY") }>Sky:</span>
						<span className="col2 edit" onClick={ (e) => this.showInput("SKY") }><b>{this.state.atis.ceiling_1}00' {this.state.atis.coverage_1_abbrev}</b></span>

						{this.state.atis.ceiling_2 > 0 ?
							<>
								<span className="col1 right edit" onClick={ (e) => this.showInput("SKY2") }></span>
								<span className="col2 edit" onClick={ (e) => this.showInput("SKY2") }><b>{this.state.atis.ceiling_2}00' {this.state.atis.coverage_2_abbrev}</b></span>
							</>
							: null
						}

						{this.state.atis.ceiling_3 > 0 ?
							<>
								<span className="col1 right edit" onClick={ (e) => this.showInput("SKY3") }></span>
								<span className="col2 edit" onClick={ (e) => this.showInput("SKY3") }><b>{this.state.atis.ceiling_3}00' {this.state.atis.coverage_3_abbrev}</b></span>
							</>
							: null
						}

						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("TEMP") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("TEMP") }>Temp:</span>
						<span className="col2 edit" onClick={ (e) => this.showInput("TEMP") }><b>{this.state.atis.temp}</b> &deg;C</span>
						<span className="col3 edit" onClick={ (e) => this.showInput("TEMP") }>Dewpoint: <b>{this.state.atis.dewpoint}</b> &deg;C</span>

						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("ALT") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("ALT") }>Altimeter:</span>
						<span className="col2 edit" onClick={ (e) => this.showInput("ALT") }><b>{this.state.atis.altimeter}</b> inHg</span>

						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("RWY") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("RWY") }>Runway:</span>
						<span className="col2 edit" onClick={ (e) => this.showInput("RWY") }><b>{this.state.atis.runway_in_use}</b></span>

						<FaEdit className="col0 edit" onClick={ (e) => this.showInput("REM") }/>
						<span className="col1 right edit" onClick={ (e) => this.showInput("REM") }>Remarks:</span>
						<span className="col2 edit" onClick={ (e) => this.showInput("REM") }><i>{this.state.atis.remarks}</i></span>
					</div>
				</div>
				{this.state.seen ? <PopUp toggle={this.togglePop} formType={this.getForm(this.state.formType)} /> : null}

			</div>
		</SpecificAircraftPage>
	  );
	}
  }

export default Atis;