import styled from "styled-components";

import { NavLink as Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';

// Colors:
// Base: 2e4172
// light->dark
// 7887ab
// 4f618f
// 162856
// 061539

export const Nav = styled.nav`
	background: #061539;
	height: 60px;
	display: flex;
	align-items: center;
	padding: 0;
	z-index: 10;

	justify-content: flex-start;
`
export const NavLink = styled(Link)`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&.active {
		color: #256ce1;
	}

	&:hover {
		background: #4f618f;
		color: #061539;
		border-radius: 4px;
		outline: none;
		transition: all 0.3s ease-in-out;
	}
`
export const Bars = styled(FaBars)`
	display: none;
	color: #fff;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}
`
export const NavMenu = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 0px;

	width: 100vw;
	white-space: nowrap;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 60px;
		z-index: 100;
		background: #162856;
		opacity: 0.95;

		&.menu-show {
			left: 0px;
			transition: all 0.5s ease;
		}

		&.menu-hide {
			left: -150%;
			transition: all 0.5s ease;
		}
	}
`

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 0;

	justify-content: flex-end;
	width: 10vw;
	white-space: nowrap;

	@media screen and (max-width: 768px) {
		display: none;
	}
`
export const NavBtnLink = styled(Link)`
	border-radius: 4px;
	background: #256ce1;
	padding: 10px 22px;
	color: #fff;
	border: none;
	outline: none;
	cursor: pointer;
	transtion: all 0.3s ease-in-out;
	text-decoration: none;

	&:hover {
		transtion: all 0.3s ease-in-out;
		background: #fff;
		color: #010606;
	}
`