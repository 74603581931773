import plane from './plane';

function PersistLoading(plane) {
	localStorage.setItem(plane.aircraft, JSON.stringify(plane.loading));
  }
  
  function InitializeLoading(plane) {
	let local = localStorage.getItem(plane.aircraft);
	if (local != null)
	{
	  plane.changeLoading(JSON.parse(local));
	}
  }
  
  class wfrfPlanes {

	constructor(props) {
  
	  let n8707b = new plane('N8707B',
	  { fuel: 48,
		oil: -20,
		front_seat: 36,
		rear_seat: 70,
		baggage: 95,
		baggage2: null },
	  { effective_date: "07-03-2005",
		ramp: 2200,
		take_off: 2200,
		landing: 2200,
		baggage: 120,
		forward_cg: [
		  { weight: 2200, arm: 40.3 },
		  { weight: 1750, arm: 36.4 },
		  { weight: 1500, arm: 36.4 }
		],
		aft_cg: [
		  { weight: 2200, arm: 46.4 },
		  { weight: 1500, arm: 46.4 }
		]
	  },
	  { weight: 1375.9, arm: 36.97 });
   
	  n8707b.loading = { 
		 fuel: 37,
		 oil: 8,
		 pilot: 215,
		 passenger: 180,
		 rear_one: 10,
		 rear_two: 15,
		 baggage: 25,
		 taxi_runup_fuel_burn: 1.4,
		 cruise_fuel_burn: 20
	  };
	  InitializeLoading(n8707b);
  
	  n8707b.notes = "The club has provided ballast to help avoid forward cg errors.  The black bag is 25.8lbs, gunny sack is 25.4lbs, and blue RAM bag is 18.6lbs."

	  let n124ee = new plane('N124EE',
	  { fuel: 48,
		oil: null,
		front_seat: 37,
		rear_seat: 72.9,
		baggage: 94.8,
		baggage2: 120 },
	  { effective_date: "04-29-2020",
		ramp: 2558,
		take_off: 2550,
		landing: 2550,
		baggage: 120,
		baggage2: 50,
		baggage_combined: 120,
		forward_cg: [
		  { weight: 2550, arm: 41.0 },
		  { weight: 1950, arm: 35.0 },
		  { weight: 1500, arm: 35.0 }
		],
		aft_cg: [
		  { weight: 2550, arm: 47.3 },
		  { weight: 1500, arm: 47.3 }
		]
	  },
	  { weight: 1407.64, arm: 35.2918 });
   
	  n124ee.loading = { 
		 fuel: 40,
		 oil: 6,
		 pilot: 205,
		 passenger: 180,
		 rear_one: 200,
		 rear_two: 120,
		 baggage: 70,
		 baggage2: 50,
		 taxi_runup_fuel_burn: 1.4,
		 cruise_fuel_burn: 20
	  };
	  InitializeLoading(n124ee);
  
	  let n15601 = new plane('N15601',
							{ fuel: 95,
							  oil: 29.1,
							  front_seat: 80.5,
							  rear_seat: 118.1,
							  baggage: 142.8,
							  baggage2: null },
							{ effective_date: "09-27-2021",
							  ramp: 3011,
							  take_off: 3000,
							  landing: 3000,
							  front_seat: 400,
							  rear_seat: 400,
							  baggage: 200,
							  forward_cg: [
								{ weight: 3000, arm: 88.0 },
								{ weight: 2500, arm: 82.0 },
								{ weight: 1900, arm: 81.5 },
								{ weight: 1800, arm: 81.5 }
							  ],
							  aft_cg: [
								{ weight: 3000, arm: 91.5 },
								{ weight: 1800, arm: 91.5 }
							  ]
							},
							{ weight: 1621.69, arm: 86.4 });
   
	  n15601.loading = { 
		 fuel: 82,
		 oil: 9,
		 pilot: 215,
		 passenger: 180,
		 rear_one: 200,
		 rear_two: 120,
		 baggage: 70,
		 taxi_runup_fuel_burn: 1.8,
		 cruise_fuel_burn: 20
	  };
	  InitializeLoading(n15601);
  
	  let plane_list = [ n124ee, n15601, n8707b ];
  
	  this.plane_list = plane_list;
	}
	
  }
  
  export default wfrfPlanes;
  