import './WFRF.css';
import './WnB.css';

import React from "react";
import NavBar from './components/NavBar/index.js';
import { Formik, Field, Form } from 'formik';

const SELECT_VALUE_KEY = "MySelectValue";

class SpecificAircraftPage extends React.Component {

  constructor(props) {
    super(props);
    const current_aircraft = JSON.parse(localStorage.getItem(SELECT_VALUE_KEY) ?? null);

    this.SelectPlane = this.SelectPlane.bind(this);
	
    this.state = {
      planes: props.planes,
      selected: current_aircraft,
      wbLink: props.wbLink,
    };

  }

  findPlane(array, aircraft) {
    return array.find((plane) => {
      return plane.aircraft === aircraft;
    })
  }

  SelectPlane(event) {
    var aircraft = event.target.value;

	localStorage.setItem(SELECT_VALUE_KEY, JSON.stringify(aircraft));

	this.setState({ selected: aircraft });

	this.props.onSelectPlane(event);
  }

  render() {

    const listItems = this.state.planes.map((plane, index) =>
      <option key={index} value={plane.aircraft}>{plane.aircraft}</option>
    );

    return (
	  <div className="app">
        <div className="app-header">
          <NavBar wbLink={ this.state.wbLink }/>
        </div>
		<div className="app-pre-body">
			<Formik>

              {props => (
                <Form className="input-form">
                  <label className="form-header">Aircraft:</label>
                  <select className="form-aircraft" value={this.state.selected} onChange={ this.SelectPlane }>
                    {listItems}
                  </select>
				</Form>
			  )}
			</Formik>
		</div>
        <div className="app-footer">
          <div>Copyright &copy;2021 ground-effect.com</div>
        </div>
		{this.props.children}
      </div>
    );
  }
}

export default SpecificAircraftPage;