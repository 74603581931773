import './IFR.css';
import React from 'react';

import logo from '../img/OnPurposeLogo.png';
import otto from '../img/autopilot2.svg';

import { PlayerIcon } from 'react-player-controls'

class Video extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showScreen: false };
      };

     componentDidMount = () => {
      this.playVideo();
    };

    componentWillUnmount = () => {
        this.pauseVideo();
    };


    playVideo = () => {
      // You can use the play method as normal on your video ref
      this.refs.vidRef.play();
    };

    pauseVideo = () => {
      // Pause as well
      this.refs.vidRef.pause();
    };

    showVideo = () => {
        this.setState( { showScreen: !this.state.showScreen });
    }

    render = () => {
      return (
        <div>
          <div>
          <ul id="video-controls" class="controls">
            <li><PlayerIcon.Play onClick={this.playVideo} /></li>
            <li><PlayerIcon.Pause onClick={this.pauseVideo} /></li>
            <li><div className="transparent-button" onClick={this.showVideo}>
                <img src={otto} class="tip-icon" alt="otto pilot" title="Follow along with Me!"/>
              </div>
            </li>
            </ul>
          </div>
          <video playsInline
            ref="vidRef"
            src={this.props.src}
            type="video/mp4"
            autoPlay={false}
            style={{display: this.state.showScreen ? 'block' : 'none' }}
          />

        </div>
      );
    };
  }

class ClearanceSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clearance: props.clearance,
      selectClearance: props.selectClearance,
    };
  }

  render() {

    return (
      <div className={this.state.clearance.selected ? "clearance_summary_selected" : "clearance_summary"} onClick={() => {this.state.selectClearance(this.state.clearance)}} >
          {this.state.clearance.departure} -{'>'} {this.state.clearance.destination}
      </div>
    );
  }

}

class ClearanceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clearances: props.clearances,
      selectClearance: props.selectClearance,
    };
  }

  render() {

    const listItems = this.state.clearances.map((clearance) =>
      <ClearanceSummary clearance={clearance} selectClearance={this.state.selectClearance}/>
    );

    return (
      <div className="clearance_list">
          {listItems}
      </div>
    );
  }
}

class FlightPlan extends React.Component {

  render() {

    return (
      <div class="flight-plan">
        <div className="aircraft" ><em>{this.props.clearance.aircraft}</em></div>
        <div className="time">{this.props.clearance.departure_time}</div>
        <div className="altitude">{this.props.clearance.altitude}</div>
        <div className="route">{this.props.clearance.departure} {this.props.clearance.route} {this.props.clearance.destination}</div>
      </div>
    );
  }
}

class ClearancePanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clearances: props.clearances,
    };
  }

  render() {

    return (
      <div className="Clearance-panel">
        <FlightPlan clearance={this.props.current_clearance}/>
        <Video src={this.props.current_clearance.video_src}/>
      </div>
    );
  }

}

class ClearancePlayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clearances: props.clearances,
      current_clearance: props.clearances[0],
    };
    this.SelectClearance = this.SelectClearance.bind(this);
    this.state.current_clearance.selected = true;
  }

  SelectClearance(clearance) {
    this.state.current_clearance.selected = false;
    this.setState({ current_clearance: clearance});
    clearance.selected = true;
  }

    render() {

      const current_clearance = this.state.current_clearance;

      return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div className="App-group">
            <ClearanceList clearances={this.state.clearances} selectClearance={this.SelectClearance}/>
            <ClearancePanel clearances={this.state.clearances} current_clearance={current_clearance}/>
          </div>
        </header>
      </div>
    );
  }
}

// mp4 starts with 540p, medium quality
// mp4's should be 240x344
// Crop Left: 290, Top: 0, Width: 380, Height: 540
class ClearancePractice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clearances: [
        { aircraft: 'NCFKMA',
          departure_time: '1545Z',
          altitude: '6000',
          departure: 'KUES',
          destination: 'KOFK',
          route: 'GREAS JAYEX',
          video_src: '/KMKE-KOFK.mp4' },
        { aircraft: 'N23850',
          departure_time: '1655Z',
          altitude: '5000',
          departure: 'KUES',
          destination: 'KETB',
          route: 'D->',
          video_src: '/KUES-KETB.mp4' },
        { aircraft: 'N10769',
          departure_time: '2230Z',
          altitude: 'FL400',
          departure: 'KUES',
          destination: 'KOAK',
          route: 'D->',
          video_src: '/KUES-KOAK.mp4' },
        { aircraft: 'NR23VP',
          departure_time: '2545Z',
          altitude: 'FL410',
          departure: 'KUES',
          destination: 'KBOS',
          route: 'SQUIB MKG ALB GDM4',
          video_src: '/KUES-KBOS.mp4' },
        { aircraft: 'N12345',
          departure_time: '1210Z',
          altitude: 'FL300',
          departure: 'KUES',
          destination: 'KCMH',
          route: 'SQUIB BENJO FBC GUNNE',
          video_src: '/KUES-KCMH.mp4' },
        { aircraft: 'N23850',
          departure_time: '1750Z',
          altitude: '4000',
          departure: 'KMKE',
          destination: 'KETB',
          route: 'POBER CORIR',
          video_src: '/KMKE-KETB.mp4' },
        { aircraft: 'N12345',
          departure_time: '1120Z',
          altitude: 'FL400',
          departure: 'KUES',
          destination: 'KSTE',
          route: 'D->',
          video_src: '/KUES-KSTE.mp4' },
        { aircraft: 'N341AE',
          departure_time: '1010Z',
          altitude: 'FL450',
          departure: 'KMKE',
          destination: 'KMMU',
          route: 'SQUIB VIO DEWIT JHW LVZ4',
          video_src: '/KKME-KMMU.mp4' },
        { aircraft: 'N1B17',
          departure_time: '2215Z',
          altitude: 'FL260',
          departure: 'KUES',
          destination: 'KRST',
          route: 'HAWKN DLL EMORE',
          video_src: '/KUES-KRST.mp4' },
        { aircraft: 'NSC280',
          departure_time: '2115Z',
          altitude: 'FL450',
          departure: 'KUES',
          destination: 'KPBI',
          route: 'BVT SOT IRQ OMN TUXXI3',
          video_src: '/KUES-KPBI.mp4' }
      ],
    };
  }
  render() {
      return (
      <ClearancePlayer
       clearances={ this.state.clearances }
       />
    );
  }
}


export default ClearancePractice;
